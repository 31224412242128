import { Types } from 'mongoose';

export enum PaymentStatus {
  CREATED = 'Created',
  PENDING = 'Pending',
  VERIFICATION = 'Verification',
  APPROVED = 'Approved',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected'
}

export enum FiatCurrency {
  UAH = 'UAH',
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP'
}

export enum CryptoCurrency {
  USDT = 'USDT',
  BTC = 'BTC'
}

export interface IPayment {
  id?: Types.ObjectId | string;
  status: PaymentStatus;
  amount: number;
  currency: FiatCurrency;
  exchangeRate: number;
  teamId?: Types.ObjectId | any;
  cardId?: Types.ObjectId | any;
  partner?: Types.ObjectId | any;
}

export interface ICreatePaymentRes {
  paymentId: string;
  paymentLink: string;
  // TODO: Implement payment token
  // paymentToken: string;
}

// TODO: Finish Payments sorting
export enum PaymentSortingFields {
  Id = 'id'
}
export const PaymentSortingParams = Object.values(PaymentSortingFields);

export enum BalanceUpdateType {
  REWARD = 'reward',
  EXPENSE = 'expense'
}

export enum BalanceUpdateTarget {
  TEAM = 'team',
  PARTNER = 'partner',
  SERVICE = 'service'
}

export interface IBalanceUpdate {
  type: BalanceUpdateType;
  target: BalanceUpdateTarget;
  teamId?: Types.ObjectId | string;
  partnerId?: Types.ObjectId | string;
  paymentId?: Types.ObjectId | string;
  amount: number;
  value: number;
  createdAt?: Date;
  updatedAt?: Date;
}
