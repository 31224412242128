// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Heading.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Heading.tsx");
  import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

import { cn } from "../utils/styles/cn";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";
const headingVariants = cva("font-extrabold", {
  variants: {
    type: {
      h1: "text-4xl",
      h2: "text-3xl",
      h3: "text-2xl",
      h4: "text-xl",
      h5: "text-lg",
      h6: "text-lg"
    }
  },
  defaultVariants: {
    type: "h2"
  }
});
const Heading = forwardRef(_c = ({
  as,
  type = "h2",
  children,
  className,
  ...props
}, ref) => {
  const Comp = as ?? type;
  return <Comp ref={ref} className={cn(headingVariants({
    type
  }), className)} {...props}>
        {children}
      </Comp>;
});
_c2 = Heading;
Heading.displayName = "Heading";
export { Heading };
var _c, _c2;
$RefreshReg$(_c, "Heading$forwardRef");
$RefreshReg$(_c2, "Heading");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;