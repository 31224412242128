import { IMetrics } from './common';
import { FiatCurrency } from './payment';

export enum CardStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended'
}

export enum CardIssuers {
  VISA = 'visa',
  MASTER_CARD = 'master_card',
  AMERICAN_EXPRESS = 'american_express'
}

export enum CardSupportedBanks {
  TINKOFF = 'tinkoff',
  OSCHADBANK = 'oschadbank',
  MONOBANK = 'monobank',
  PRIVAT24 = 'privat24',
  SBERBANK = 'sberbank',
  ALFA_BANK = 'alfa_bank',
  VTB = 'vtb'
}

export interface ICardInfo {
  cardNumber: string;
  holderName: string;
  expirationDate: string;
  phone: string;
  issuer: CardIssuers;
  bank: CardSupportedBanks;
  currency: FiatCurrency;
  username?: string;
  accId?: string;
}

export interface ICardMetrics extends IMetrics {}

export interface ICard {
  id?: string;
  balance: number;
  limit: number;
  status: CardStatus;
  cardInfo: ICardInfo;
  assignedUserId: string;
  teamId: string;
  cardMetrics: ICardMetrics;
}

export enum CardBalanceFilterOptions {
  ANY = 'any',
  FULL = 'full',
  AVAILABLE_VOLUME = 'available_volume'
}

export interface ICardFilters {
  holderName: string;
  status: CardStatus;
  ids: string[];
}

// TODO: Finish Card sorting
export enum CardSortingFields {
  Id = 'id',
  CreatedAt = 'createdAt'
}
export const CardSortingParams = Object.values(CardSortingFields);
