import { Types } from 'mongoose';
import { IMetrics } from './common';
import { IBalanceUpdate } from './payment';
import { UserRole } from './user';

export enum TeamStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  FROZEN = 'frozen'
}

export interface ITeam {
  id: Types.ObjectId | string;
  name: string;
  owner: Types.ObjectId | any;
  status: TeamStatus;
  balance: number;
  freezed: number;
  teamFee: number;
  members: Types.ObjectId[] | any;
  partners: Types.ObjectId[] | any;
  teamMetrics: ITeamMetrics;
  balanceHistory: IBalanceUpdate[] | any;
  createdAt?: Date;
  updatedAt?: Date;
}

export type AddMemberParams = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  teamId?: Types.ObjectId | string;
  role?: UserRole;
};

export interface ITeamMetrics extends IMetrics {}

// TODO: Finish Team sorting
export enum TeamSortingFields {
  Id = 'id',
  CreatedAt = 'createdAt'
}
export const TeamSortingParams = Object.values(TeamSortingFields);
