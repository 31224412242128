// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/payment.tsx"
);
import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

import { confirmPayment, handlePayment, startPayment } from "../api";

import { createMutation, createQuery } from "react-query-kit";

export const useStartPayment = createMutation<any, any>({
  mutationFn: async (id: string) => await startPayment(id),
});

export const useConfirmPayment = createMutation<any, any>({
  mutationFn: async (id: string) => await confirmPayment(id),
});

export const useHandlePayment = createQuery<any, any, any>({
  fetcher: async (id: string) => await handlePayment(id),
  queryKey: ["handlePayment"],
});
