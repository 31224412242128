// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/index.ts"
);
import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

import { PaymentStatus } from "@kukusiki/types";
import axios, { AxiosError } from "axios";

export const apiClient = axios.create({
  // baseURL: "http://192.168.10.156:3000",
  //baseURL: "http://localhost:3000",
  baseURL: "https://e8215b314049.ngrok.app",
});

export const confirmPayment = async (id: string) => {
  try {
    const { data: response } = await apiClient.patch<any>(
      `/payments/${id}/status`,
      {
        status: PaymentStatus.VERIFICATION,
      },
    );

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error("Failed to confirm payment");
  }
};

export const startPayment = async (payload: any) => {
  console.log(payload);
  try {
    const { data: response } = await apiClient.post<any>(`/payments`, payload);

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error("Failed to confirm payment");
  }
};

export const handlePayment = async (id: string) => {
  try {
    const { data: response } = await apiClient.get<any>(
      `/payments/${id}/handle`,
    );

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    } else if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error("Failed to confirm payment");
  }
};
